<template>
  <div>
    <ol>
      <li>
        初始化权限菜单<el-button size="mini" :loading="initFuncBtn" @click="initFunc" >执行</el-button>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'data-uphold',
  data () {
    return {
      initFuncBtn: false
    }
  },
  methods: {
    async initFunc() {
      this.initFuncBtn = true;
      try {
          this.$http.doApi('func-initFunc');
          this.$message.success('初始化成功');
      } finally {
        this.initFuncBtn = false;
      }
    }
  }
}
</script>

<style>

</style>